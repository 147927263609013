<template>
    <div class="page wallet">
        <div class="pay-model" v-if="type == 0 || type == 1">
            <div class="box">
                <div class="tip-1">
                    <img :src="require('@/assets/icons/icon-notice2.png')" alt="" />
                    <div class="span">请向收银员出示条码进行付款</div>
                </div>
                <div id="barcode-box" ref="barcodeBox">
                    <svg id="barcode"></svg>
                </div>
                <div id="walletQRCode" ref="walletQRCode"></div>
                <div class="refresh" @click="debounceGetData">
                    <img :src="require('@/assets/icons/icon-refresh.png')" alt="" />
                </div>
                <div class="tip-2">
                    <img :src="require('@/assets/icons/icon-tip.png')" alt="" />
                    <span>付款码每1分钟更新，请当面使用勿泄露</span>
                </div>
                <div class="payment-line">
                    <img
                        :src="
                            require('@/assets/icon-official/rn_temp_cashier_pages_cashier_api_paytypes_icons_balance_kfc.png')
                        "
                        alt=""
                    />
                    <span class="title">账户余额</span>
                    <span class="amount">￥{{ payment.balance || '--.--' }}</span>
                    <span class="icon"></span>
                </div>
            </div>
            <div class="recharge-box">
                <div class="title">充值</div>
                <div class="tip">(充200元赠88元券包)</div>
            </div>
        </div>
        <div class="member-model" v-else-if="type == 2">
            <div class="box">
                <div class="tip-1">会员码</div>
                <div class="header-bg"></div>
                <div id="barcode-box" ref="barcodeBox">
                    <svg id="barcode"></svg>
                </div>
                <div class="barcode-box-text">{{ payment.memberCodeDesc }}</div>
                <div id="walletQRCode" ref="walletQRCode"></div>
                <!-- <div class="refresh" @click="getData">
                    <img :src="require('@/assets/icons/icon-refresh.png')" alt="" />
                </div> -->
                <div class="tip-2">
                    <img :src="require('@/assets/icons/icon-tip.png')" alt="" />
                    <span>本会员码定时更新，请勿截图，以免影响正常使用</span>
                </div>
            </div>
            <div class="pay-box">
                <div class="title">支付方式</div>
                <div class="pay-list">
                    <div class="pay-item">
                        <img
                            :src="
                                require('@/assets/icon-official/rn_temp_packages_common_basecomponents_paytype_paytypemgmt_img_ic_alipay.png')
                            "
                            class="icon"
                        />
                        <div class="name">支付宝</div>
                    </div>
                    <div class="pay-item">
                        <img
                            :src="
                                require('@/assets/icon-official/rn_temp_packages_common_basecomponents_paytype_paytypemgmt_img_ic_wxpay.png')
                            "
                            class="icon"
                        />
                        <div class="name">微信</div>
                    </div>
                    <div class="pay-item">
                        <img
                            :src="
                                require('@/assets/icon-official/rn_temp_packages_common_basecomponents_paytype_paytypemgmt_img_ic_vpay.png')
                            "
                            class="icon"
                        />
                        <div class="name">神钱包</div>
                    </div>
                </div>
            </div>
            <div class="tabbar-list iphone-x-adaptation">
                <div class="tabbar-item">
                    <img class="icon" src="~@/assets/icon-official/kfc_tab_tab_1_n.png" />
                </div>
                <div class="tabbar-item">
                    <img class="icon" src="~@/assets/icon-official/kfc_tab_tab_2_n.png" />
                </div>
                <div class="tabbar-item select">
                    <img class="icon" src="~@/assets/icon-official/kfc_tab_tab_5_n.png" />
                </div>
                <div class="tabbar-item">
                    <img class="icon" src="~@/assets/icon-official/kfc_tab_tab_3_n.png" />
                </div>
                <div class="tabbar-item">
                    <img class="icon" src="~@/assets/icon-official/kfc_tab_tab_4_n.png" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import QRCode from 'qrcodejs2';
import JsBarcode from 'jsbarcode';
import { GET_MEMBER_CODE, GET_PAY_CODE } from 'api';
let refreshTimer = null;

export default {
    data() {
        return {
            type: 0,
            walletQRCodeObject: null,
            walletBarCodeObject: null,
            barcodeHeight: 0,
            qrcodeHeight: 0,
            payment: {},
            orderNo: '',
            itemId: '',
        };
    },
    created() {
        this.type = this.$route.query.type || 0;
        this.orderNo = this.$route.query.orderNo || '';
        this.itemId = this.$route.query.itemId || '';
        this.getData();
    },
    mounted() {
        this.barcodeHeight = this.$refs.barcodeBox.clientHeight;
        this.qrcodeHeight = this.$refs.walletQRCode.clientHeight;
    },
    methods: {
        getData() {
            if (this._isDestroyed) {
                return;
            }
            if (this.type == 0) {
                this.$util.loading('正在加载');
                this.$util
                    .get(`http://121.43.177.166:33003/kfcAccounts/getByUuid`, {
                        uuid: this.$route.query.uuid,
                    })
                    .then(res => {
                        this.$util.unloading();
                        if (res.success) {
                            this.payment = res.data;
                            let seconds = moment(res.data.validEndTime).diff(moment(), 'seconds');
                            if (refreshTimer) {
                                clearTimeout(refreshTimer);
                                refreshTimer = null;
                            }
                            refreshTimer = setTimeout(() => {
                                this.getData();
                            }, seconds * 1000);
                            if (this.walletQRCodeObject) {
                                this.walletQRCodeObject.clear();
                                this.walletQRCodeObject = null;
                                this.$refs.walletQRCode.innerHTML = '';
                            }
                            this.walletQRCodeObject = new QRCode(
                                document.getElementById('walletQRCode'),
                                {
                                    text: this.payment.paymentCode,
                                    width: this.qrcodeHeight,
                                    height: this.qrcodeHeight,
                                    colorDark: '#000000',
                                    colorLight: '#ffffff',
                                    correctLevel: QRCode.CorrectLevel.H,
                                }
                            );
                            JsBarcode('#barcode', this.payment.paymentCode, {
                                format: 'CODE128', // 指定条形码的格式
                                lineColor: '#000', // 条形码颜色
                                width: 2, // 条的宽度
                                height: this.barcodeHeight, // 条形码的高度
                                displayValue: false, // 是否显示文本
                                margin: 0,
                            });
                        }
                    });
            } else if (this.type == 1) {
                this.$util.loading('正在加载');
                this.$util
                    .get(GET_PAY_CODE, {
                        orderNo: this.orderNo,
                        itemId: this.itemId,
                    })
                    .then(res => {
                        this.$util.unloading();
                        if (res.success) {
                            this.payment = res.data;
                            if (refreshTimer) {
                                clearTimeout(refreshTimer);
                            }
                            refreshTimer = setTimeout(() => {
                                this.getData();
                            }, 60 * 1000);
                            if (this.walletQRCodeObject) {
                                this.walletQRCodeObject.clear();
                                this.walletQRCodeObject = null;
                                this.$refs.walletQRCode.innerHTML = '';
                            }
                            this.walletQRCodeObject = new QRCode(
                                document.getElementById('walletQRCode'),
                                {
                                    text: this.payment.paymentCode,
                                    width: this.qrcodeHeight,
                                    height: this.qrcodeHeight,
                                    colorDark: '#000000',
                                    colorLight: '#ffffff',
                                    correctLevel: QRCode.CorrectLevel.H,
                                }
                            );
                            JsBarcode('#barcode', this.payment.paymentCode, {
                                format: 'CODE128', // 指定条形码的格式
                                lineColor: '#000', // 条形码颜色
                                width: 2, // 条的宽度
                                height: this.barcodeHeight, // 条形码的高度
                                displayValue: false, // 是否显示文本
                                margin: 0,
                            });
                        }
                    });
            } else if (this.type == 2) {
                this.$util.loading('正在加载');
                this.$util
                    .get(GET_MEMBER_CODE, {
                        orderNo: this.orderNo,
                        itemId: this.itemId,
                    })
                    .then(res => {
                        this.$util.unloading();
                        if (res.success) {
                            res.data.memberCodeDesc = res.data.memberCode
                                .split('')
                                .reverse()
                                .join('')
                                .replace(/(.{4})/g, '$1 ')
                                .split('')
                                .reverse()
                                .join('');
                            this.payment = res.data;
                            let seconds = moment(res.data.validEndTime).diff(moment(), 'seconds');
                            if (this.walletQRCodeObject) {
                                this.walletQRCodeObject.clear();
                                this.walletQRCodeObject = null;
                                this.$refs.walletQRCode.innerHTML = '';
                            }
                            this.walletQRCodeObject = new QRCode(
                                document.getElementById('walletQRCode'),
                                {
                                    text: this.payment.memberCode,
                                    width: this.qrcodeHeight,
                                    height: this.qrcodeHeight,
                                    colorDark: '#000000',
                                    colorLight: '#ffffff',
                                    correctLevel: QRCode.CorrectLevel.H,
                                }
                            );
                            JsBarcode('#barcode', this.payment.memberCode, {
                                format: 'CODE128', // 指定条形码的格式
                                lineColor: '#000', // 条形码颜色
                                width: 2, // 条的宽度
                                height: this.barcodeHeight, // 条形码的高度
                                displayValue: false, // 是否显示文本
                                margin: 0,
                            });
                        }
                    });
            }
        },
        debounceGetData() {
            this.$util.debounce(this.getData)();
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.wallet {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    .pay-model {
        min-height: 100vh;
        padding: .pxToRem(30) [] .pxToRem(20) [];
        background: #d90209;
        box-sizing: border-box;
        .box {
            height: .pxToRem(1200) [];
            background: white;
            border-radius: .pxToRem(40) [];
            text-align: center;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
            #walletQRCode {
                position: absolute;
                top: .pxToRem(520) [];
                left: 50%;
                margin-left: .pxToRem(-120) [];
                width: .pxToRem(240) [];
                height: .pxToRem(240) [];
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            #barcode-box {
                position: absolute;
                display: flex;
                top: .pxToRem(304) [];
                left: 0;
                right: 0;
                height: .pxToRem(190) [];
            }
            #barcode {
                margin: 0 auto;
            }
            .tip-1 {
                position: absolute;
                top: .pxToRem(120) [];
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: .pxToRem(64) [];
                line-height: .pxToRem(64) [];
                font-weight: 400;
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.5);
                background: #f2f2f2;
                text-align: center;
                img {
                    width: .pxToRem(40) [];
                    height: .pxToRem(40) [];
                    margin-right: .pxToRem(8) [];
                }
            }
            .tip-2 {
                position: absolute;
                top: .pxToRem(920) [];
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(0, 0, 0, 0.6);
                img {
                    width: .pxToRem(32) [];
                    height: .pxToRem(32) [];
                    margin-right: .pxToRem(4) [];
                }
            }
            .refresh {
                position: absolute;
                top: .pxToRem(680) [];
                right: .pxToRem(60) [];
                background: #d90209;
                padding: .pxToRem(16) [];
                font-size: 0;
                border-radius: 100%;
                img {
                    width: .pxToRem(44) [];
                    height: .pxToRem(44) [];
                }
            }
            .payment-line {
                position: absolute;
                bottom: .pxToRem(90) [];
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(32) [];
                img {
                    width: .pxToRem(48) [];
                    height: .pxToRem(48) [];
                    margin-right: .pxToRem(8) [];
                }
                .title {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.9);
                }
                .amount {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.9);
                    margin-left: .pxToRem(10) [];
                }
                .icon {
                    color: rgba(0, 0, 0, 0.3);
                    margin-left: .pxToRem(8) [];
                    width: .pxToRem(12) [];
                    height: .pxToRem(40) [];
                    background: url('~@/assets/icon-official/arrow_rightgray.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
        .recharge-box {
            margin-top: .pxToRem(80) [];
            height: .pxToRem(80) [];
            background: white;
            border-radius: .pxToRem(10) [];
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
            .title {
                width: 100%;
                text-align: center;
                color: #dc6065;
                font-weight: bold;
                font-size: .pxToRem(28) [];
                line-height: .pxToRem(30) [];
                padding-top: .pxToRem(12) [];
            }
            .tip {
                width: 100%;
                text-align: center;
                color: #f1954f;
                font-weight: bold;
                margin-top: .pxToRem(2) [];
            }
        }
    }
    .member-model {
        min-height: .pxToRem(1600) [];
        background: white;
        .box {
            width: 100%;
            height: 100%;
            background: white;
            border-radius: .pxToRem(40) [];
            text-align: center;
            #walletQRCode {
                position: absolute;
                top: .pxToRem(630) [];
                left: 50%;
                margin-left: .pxToRem(-170) [];
                width: .pxToRem(340) [];
                height: .pxToRem(340) [];
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            #barcode-box {
                position: absolute;
                display: flex;
                top: .pxToRem(334) [];
                left: 0;
                right: 0;
                height: .pxToRem(180) [];
            }
            .barcode-box-text {
                position: absolute;
                top: .pxToRem(530) [];
                left: 0;
                right: 0;
                text-align: center;
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                font-size: .pxToRem(28) [];
                color: rgba(0, 0, 0, 0.5);
            }
            #barcode {
                margin: 0 auto;
            }
            .tip-1 {
                position: absolute;
                top: .pxToRem(40) [];
                left: 0;
                right: 0;
                font-size: .pxToRem(32) [];
                font-weight: bold;
            }
            .header-bg {
                position: absolute;
                top: .pxToRem(200) [];
                width: .pxToRem(630) [];
                left: 50%;
                transform: translateX(-50%);
                height: .pxToRem(66) [];
                margin: 0 auto;
                overflow: hidden;
                border-top-left-radius: .pxToRem(20) [];
                border-top-right-radius: .pxToRem(20) [];
                &::before {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: .pxToRem(5227) [];
                    height: .pxToRem(5227) [];
                    transform: translateX(-50%);
                    background: #d90209;
                    border-radius: 100%;
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    top: .pxToRem(10) [];
                    left: 50%;
                    transform: translateX(-50%);
                    height: .pxToRem(42) [];
                    width: .pxToRem(166) [];
                    background: url('~@/assets/icon-official/vipcode_wow.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
            .tip-2 {
                position: absolute;
                top: .pxToRem(1020) [];
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(0, 0, 0, 0.6);
                img {
                    width: .pxToRem(32) [];
                    height: .pxToRem(32) [];
                    margin-right: .pxToRem(12) [];
                }
            }
            .refresh {
                position: absolute;
                top: .pxToRem(900) [];
                right: .pxToRem(60) [];
                background: #d90209;
                padding: .pxToRem(16) [];
                font-size: 0;
                border-radius: 100%;
                img {
                    width: .pxToRem(44) [];
                    height: .pxToRem(44) [];
                }
            }
            .payment-line {
                position: absolute;
                bottom: .pxToRem(80) [];
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                img {
                    width: .pxToRem(40) [];
                    height: .pxToRem(40) [];
                    margin-right: .pxToRem(8) [];
                }
                .title {
                    font-weight: 400;
                    font-size: .pxToRem(26) [];
                    color: rgba(0, 0, 0, 0.9);
                    margin-right: .pxToRem(24) [];
                }
                .amount {
                    font-weight: 400;
                    font-size: .pxToRem(26) [];
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
        .pay-box {
            position: absolute;
            top: .pxToRem(1200) [];
            left: 0;
            right: 0;
            .title {
                text-align: center;
                font-size: .pxToRem(28) [];
                height: .pxToRem(28) [];
                line-height: .pxToRem(28) [];
                color: rgba(0, 0, 0, 0.9);
                margin-bottom: .pxToRem(50) [];
                &::after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: .pxToRem(100) [];
                    transform: translateY(-50%);
                    height: .pxToRem(1) [];
                    width: .pxToRem(180) [];
                    background: #e3e3e3;
                }
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    right: .pxToRem(100) [];
                    transform: translateY(-50%);
                    height: .pxToRem(1) [];
                    width: .pxToRem(180) [];
                    background: #e3e3e3;
                }
            }
            .pay-list {
                display: flex;
                padding: 0 .pxToRem(100) [];
                .pay-item {
                    flex: 1;
                    text-align: center;
                    .icon {
                        width: .pxToRem(72) [];
                        height: .pxToRem(72) [];
                    }
                    .name {
                        text-align: center;
                        color: rgba(0, 0, 0, 0.6);
                        margin-top: .pxToRem(8) [];
                        font-size: .pxToRem(24) [];
                    }
                }
            }
        }
        .tabbar-list {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: .pxToRem(120) [];
            display: flex;
            background: white;
            .tabbar-item {
                width: 20vw;
                text-align: center;
                display: flex;
                justify-content: center;
                .icon {
                    position: absolute;
                    bottom: 0;
                    width: .pxToRem(144 * 0.7) [];
                    height: .pxToRem(120 * 0.7) [];
                }
                &.select {
                    .icon {
                        width: .pxToRem(270 * 0.66) [];
                        height: .pxToRem(156 * 0.66) [];
                    }
                }
            }
        }
    }
}
</style>
